import { LegacyRef, useCallback, useEffect, useRef, useState } from 'react';
import Confetti from 'react-confetti';
import ReactDOM from 'react-dom';
import { useMeasure } from 'react-use';
import csx from 'classnames';
import { useRouter } from 'next/router';

import { Model, Reference } from '@grid-is/apiary';
import { CellCSF } from '@grid-is/apiary/lib/csf';
import { getViewport } from '@grid-is/browser-utils';
import { tracking } from '@grid-is/tracking';
import { assert } from '@grid-is/validation';

import { DocumentType } from '@/api/document';
import { setUserProperty } from '@/api/user';
import { EventStream } from '@/editor/EditorEventStream';
import { titleCase } from '@/grid/utils';
import { Button } from '@/grid-ui/Button';
import { confirmation } from '@/grid-ui/Confirmation';
import { IconButton } from '@/grid-ui/IconButton';

import { PostTutorialOptions } from './Components/PostTutorialOptions';
import { TutorialPreviewMessage } from './Components/TutorialPreviewMessage';
import { abTestTutorials, tutorials, TutorialsType } from './Tutorials';

import styles from './documentTutorial.module.scss';

/**
 *
 * @param model
 * @param clipboardCells
 * @returns {boolean} returns true if managed to paste data to workbook else returns false
 */
function writeClipboardCells (model: Model, clipboardCells: Record<string, CellCSF> | undefined = {}, columnWidths: Record<number, number> = {}) {
  // Assumption that GRID Sheet is the only native workbook in document
  const wb = model.getWorkbooks().find(wb => wb.type === 'native');
  assert(wb);
  const sheet = wb.getSheet();
  if (sheet) {
    Object.keys(columnWidths).forEach(colIndex => {
      wb.setColumnWidth(sheet.name, Number(colIndex), columnWidths[colIndex]);
    });
    Object.keys(clipboardCells).forEach(cellId => {
      const cellData = clipboardCells[cellId];
      const reference = new Reference(`${sheet.name}!${cellId}`);
      wb.writeCellData(reference, cellData);
    });
    model.recalculate();
    model.emit('native-update', {
      workbookName: wb.name,
      workbookId: wb.id,
      lastWrite: model.lastWrite,
      action: 'paste',
    });
  }
}
function withinTarget (id, elm) {
  const elements = [ ...document.querySelectorAll(`[data-obid="${id}"]`) ];
  return elements.some(element => element.contains(elm));
}

function isInputTarget (target, mainTarget) {
  return target && typeof target.getAttribute === 'function' && target.getAttribute('data-obid') === mainTarget;
}

function getIntro (initiatedFrom) {
  switch (initiatedFrom) {
    case 'notion-page-on-grid-website':
    case 'notion-integration':
    case 'signup': return {
      title: (<>Hi <span className={styles.wave}>👋</span></>),
      body: (
        <>
          <p className={styles.body}>This is Calculator Studio. Get quickly up to speed by completing this short 2 minute tutorial.</p>
          <p className={styles.body}>During the tutorial, you'll only be able to interact with the parts of Calculator Studio that will help you complete each step.</p>
        </>
      ),
      showAbortOption: true,
    };
    case 'tutorial-gallery': return {
      title: 'Before we start',
      body: 'Remember that you\'ll only be able to interact with the parts of Calculator Studio that will help you complete each step.',
    };
    default: return {
      title: (<>Hi <span className={styles.wave}>👋</span></>),
      body: (<>We're going to keep this short & sweet. During the tutorial, you'll only be able to interact with the parts of Calculator Studio that will help you complete each step.</>),
    };
  }
}

const tutorialTargets = [ 'ob-exit', 'ob-button', 'ob-continue', 'confirmation-dialog' ];

type DocumentTutorialProps = {
  tutorialName: TutorialsType,
  onExit: () => void,
  doc: {type: DocumentType, isDirty: boolean, model: Model, save: () => Promise<boolean | undefined>},
}

export type TutorialExitPaths = 'New Document' | 'Home Page' | 'Continue Editing' | 'Tutorial Gallery' | 'Examples';

export const DocumentTutorial = ({ tutorialName, onExit, doc }: DocumentTutorialProps) => {
  const router = useRouter();
  const tutorial = tutorials[tutorialName] || abTestTutorials[tutorialName];
  const mode = router.query.mode?.toString() as 'build' | 'preview';
  const instructions = mode === 'build' ? tutorial.instructions : tutorial.previewInstructions;
  const [ index, setIndex ] = useState(tutorial.skipIntro || mode === 'preview' ? 0 : -1);
  const [ rippleProps, setRippleProps ] = useState({ x: 0, y: 0, size: 0 });
  const [ rageClickCount, setRageClickCount ] = useState(0);
  const [ showConfetti, setShowConfetti ] = useState(false);
  const [ showPreviewMessage, setShowPreviewMessage ] = useState(mode === 'preview');
  const [ tutorialPosition, setTutorialPosition ] = useState({ x: '0px', y: '0px' });
  const [ postTutorialOption, setPostTutorialOption ] = useState<undefined | 'abort' | 'success'>(undefined);
  const tutorialRef = useRef<HTMLDivElement>();
  const [ ref, { height: tutorialHeight, width: tutorialWidth } ] = useMeasure<HTMLDivElement>();
  const rippleRef = useRef<HTMLDivElement>(null);
  const initiatedFrom = router.query['initiated-from'];

  const updateTutorialPosition = useCallback(placement => {
    const PADDING = 24;
    const height = tutorialHeight;
    const width = tutorialWidth;
    let x = `(100vw - ${width}px) / 2`; // center
    let y = `(100vh - ${height}px) / 2`; // center
    if (placement.includes('left')) {
      x = `${PADDING}px`;
    }
    else if (placement.includes('right')) {
      x = `100vw - ${width}px - ${PADDING}px`;
    }
    if (placement.includes('top')) {
      y = `${PADDING + (index > -1 ? 72 : 0)}px`;
    }
    else if (placement.includes('bottom')) {
      y = `100vh - ${height}px - ${PADDING}px`;
    }
    return setTutorialPosition({ x, y });
  }, [ tutorialHeight, tutorialWidth, index ]);

  const getStepName = useCallback(index => {
    if (instructions[index]) {
      return instructions[index].name;
    }
    return index === -1 ? 'Intro' : 'Outro';
  }, [ instructions ]);

  const trackExit = useCallback((exitPath: TutorialExitPaths) => {
    tracking.logEvent('Tutorial Successfully Exited', {
      exit_path: exitPath,
      tutorial_name: tutorialName,
    });
  }, [ tutorialName ]);

  // Hightlights the element needed to click to advance
  const ripple = useCallback(({ target, iterationCount = 3 }) => {
    if (target) {
      const offsetParent = target.offsetParent;
      if ((target.offsetHeight + target.offsetTop) > offsetParent?.scrollTop && target.offsetTop < (offsetParent?.scrollTop + offsetParent?.clientHeight)) {
        const size = 56;
        const { width, height, x, y } = target.getBoundingClientRect();
        setRippleProps({
          size,
          x: x - (size / 2) + (width / 2),
          y: y - (size / 2) + (height / 2),
        });
        const ripple = rippleRef.current;
        if (ripple) {
          ripple.classList.add(styles.on);
          setTimeout(() => {
            ripple.classList.remove(styles.on);
          }, 1500 * iterationCount);
        }
      }
    }
  }, [ rippleRef ]);

  // Shakes the tutorial box, and hightlights the element needed to click to advance
  const shake = useCallback(() => {
    const instruction = instructions[index];
    const tutorial = tutorialRef.current;
    if (tutorial) {
      tutorial.classList.add(styles.bounce);
      setTimeout(() => {
        tutorial.classList.remove(styles.bounce);
      }, 600);
    }
    if (instruction) {
      const { mainTarget } = instruction;
      const target = document.querySelector(`[data-obid="${mainTarget || ''}"]`);
      if (target) {
        setTimeout(() => {
          ripple({ target });
        }, 1000);
      }
      // Sometimes the mainTarget is hidden like in the element menu, then we try the other ones.
      else {
        const { otherTargets } = instruction;
        otherTargets?.forEach(otherTarget => {
          const target = document.querySelector(`[data-obid="${otherTarget || ''}"]`);
          if (target) {
            setTimeout(() => {
              ripple({ target });
            }, 1000);
          }
        });
      }
    }
  }, [ instructions, index, ripple ]);

  const exitTutorial = useCallback((exitVariant: 'abort' | 'success') => {
    if (initiatedFrom === 'tutorial-gallery' && exitVariant === 'success') {
      // User just completed a tutorial, coming from the gallery - so we send them back to the gallery.
      trackExit('Tutorial Gallery');
      router.replace(`/${router.query.user}?tutorials=all&accessed_from=Tutorial`);
    }
    else if (initiatedFrom !== 'tutorial-gallery') {
      setPostTutorialOption(exitVariant);
    }
    else {
      // User just aborted a tutorial, coming from the gallery, so we send them to Home.
      setRageClickCount(0);
      setIndex(instructions.length + 1);
      onExit();
      router.replace(`/${router.query.user}`);
    }
  }, [ initiatedFrom, onExit, router, instructions.length, trackExit ]);

  const onExitIntent = useCallback(() => {
    if (index === instructions.length) {
      exitTutorial('success');
    }
    else {
      confirmation({
        title: 'Leave tutorial?',
        message: 'Are you sure you want to leave the tutorial?',
        confirmLabel: 'Leave',
        cancelLabel: 'Keep going',
        onConfirm: () => {
          tracking.logEvent('Tutorial Aborted', {
            step_number: index + 1,
            step_name: getStepName(index),
            tutorial_name: tutorialName,
          });
          exitTutorial('abort');
        },
      });
    }
  }, [ index, instructions.length, tutorialName, getStepName, exitTutorial ]);

  const startTutorial = useCallback(() => {
    router.push(`/tutorial?type=${tutorialName}&initiated-from=${initiatedFrom}`);
  }, [ router, tutorialName, initiatedFrom ]);

  const advance = useCallback(() => {
    const instruction = instructions[index];
    // Advance step
    setTimeout(() => {
      const activeElement: any = document.activeElement;
      if (activeElement && !instruction?.noBlur) {
        activeElement.blur();
      }
      setRageClickCount(0);
      setIndex(index + 1);
    }, instruction?.completionDelay || 0);
    // User just completed the tutorial, celebrate.
    if ((index === instructions.length - 1 || instruction?.completionConfetti) && mode === 'build') {
      setShowConfetti(true);
      tracking.logEvent('Tutorial Completed', { tutorial_name: tutorialName });
      setUserProperty('tutorial_completed', true);
      setUserProperty(`tutorial_${tutorialName}_completed`, new Date().toISOString());
    }
    else if (index === instructions.length) {
      onExit();
    }
  }, [ instructions, index, tutorialName, onExit, mode ]);

  // When the correct target is clicked we should advance the tutorial.
  const onClick = useCallback(e => {
    const instruction = instructions[index];
    if (withinTarget('home-logo', e.target)) {
      e.stopPropagation();
      e.preventDefault();
      onExitIntent();
    }
    else if (index === -1 || index === instructions.length) {
      const isTutorialTarget = tutorialTargets.some(tutorialTarget => withinTarget(tutorialTarget, e.target));
      if (!isTutorialTarget) {
        e.stopPropagation();
        e.preventDefault();
        shake();
      }
    }
    else if (instruction) {
      const { mainTarget, otherTargets = [], completion } = instruction;
      const isTarget = withinTarget(mainTarget, e.target);
      const isOtherTarget = otherTargets.concat(tutorialTargets).some(otherTarget => withinTarget(otherTarget, e.target));
      if ((completion === 'click' || completion === 'add-element') && isTarget) {
        advance();
      }
      else if (mainTarget && !isTarget && !isOtherTarget && !instruction.unlockUi) {
        e.stopPropagation();
        e.preventDefault();
        // Jiggle things to indicate that everything else is locked.
        shake();
        setRageClickCount(rageClickCount + 1);
      }
    }
  }, [ instructions, index, advance, shake, rageClickCount, onExitIntent ]);

  // When the correct input is provided we should advance the tutorial.
  const onInput = useCallback(e => {
    const instruction = instructions[index];
    if (instruction) {
      const { mainTarget, completion, completionValue } = instructions[index];
      const target = e.target;
      let value;
      if (!target && e.value && mainTarget === `FORMULA-${e.option}`) {
        value = e.value.toLowerCase();
      }
      else if (target && isInputTarget(target, mainTarget)) {
        value = target.value?.toLowerCase() || target.innerText?.toLowerCase();
      }
      if (completion === 'value' && value) {
        if (Array.isArray(completionValue)) {
          const completionArray = completionValue.map(value => value.toLocaleLowerCase());
          if (completionArray.includes(value)) {
            advance();
          }
        }
        else if ((typeof completionValue === 'string') && value === completionValue.toLowerCase()) {
          advance();
        }
      }
    }
  }, [ instructions, index, advance ]);

  const onAddElement = useCallback(({ data }) => {
    const { type } = data;
    const instruction = instructions[index];
    if (type === instruction.completionElement) {
      advance();
    }
  }, [ instructions, index, advance ]);

  const onKeypress = useCallback(e => {
    const instruction = instructions[index];
    if (instruction?.disableKeyboard || index === -1 || index === instructions.length || e.key === 'Escape' || (instruction.completion === 'value' && e.key === 'Enter') || (instruction.completion === 'enter' && e.key !== 'Enter')) {
      e.stopPropagation();
      e.preventDefault();
    }
    else if (instruction.completion === 'enter' && e.key === 'Enter') {
      advance();
    }
  }, [ instructions, index, advance ]);

  const onBlur = useCallback(e => {
    const instruction = instructions[index];
    if (instruction) {
      const { lockFocus, mainTarget } = instruction;
      if (lockFocus && isInputTarget(e.target, mainTarget)) {
        setTimeout(function () {
          e.target.focus();
        }, 0);
      }
    }
  }, [ instructions, index ]);

  const onMouseDown = useCallback(e => {
    const instruction = instructions[index];
    if (instruction) {
      const { mainTarget, otherTargets = [] } = instruction;
      const isTarget = withinTarget(mainTarget, e.target);
      const isOtherTarget = otherTargets.concat(tutorialTargets).some(otherTarget => withinTarget(otherTarget, e.target));
      if (!isTarget && !isOtherTarget && !instruction.unlockUi) {
        e.preventDefault();
        e.stopPropagation();
      }
    }
    else {
      e.preventDefault();
      e.stopPropagation();
    }
    // During the intro and outro of the tutorial mousedown should be disabled.
    if (index === -1 || index === instructions.length) {
      e.preventDefault();
      e.stopPropagation();
    }
  }, [ instructions, index ]);

  useEffect(() => {
    document.addEventListener('click', onClick, true);
    document.addEventListener('mousedown', onMouseDown, true);
    document.addEventListener('dblclick', onMouseDown, true);
    document.addEventListener('keydown', onKeypress, true);
    document.addEventListener('input', onInput, true);
    document.addEventListener('change', onInput, true);
    document.addEventListener('blur', onBlur, true);
    EventStream.on(EventStream.FORMULA_FIELD_UPDATE, onInput);
    EventStream.on(EventStream.ELEMENT_ADD, onAddElement);

    return () => {
      document.removeEventListener('click', onClick, true);
      document.removeEventListener('mousedown', onMouseDown, true);
      document.removeEventListener('dblclick', onMouseDown, true);
      document.removeEventListener('keydown', onKeypress, true);
      document.removeEventListener('input', onInput, true);
      document.removeEventListener('change', onInput, true);
      document.removeEventListener('blur', onBlur, true);
      EventStream.off(EventStream.FORMULA_FIELD_UPDATE, onInput);
      EventStream.off(EventStream.ELEMENT_ADD, onAddElement);
    };
  }, [ onClick, onInput, onBlur, onAddElement, onKeypress, onMouseDown ]);

  useEffect(() => {
    if (initiatedFrom) {
      const words = initiatedFrom.toString().split('-');
      for (let i = 0; i < words.length; i++) {
        words[i] = titleCase(words[i]);
      }
      tracking.logEvent('Tutorial Opened', { initiated_from: words.join(' '), tutorial_name: tutorialName });
      setUserProperty('tutorial_opened', true);
    }
  }, [ initiatedFrom, tutorialName ]);

  // Move tutorial UI if needed.
  useEffect(() => {
    const instruction = instructions[index];
    const introPosition = index === -1 && tutorial.intro?.placement;
    const outroPosition = index === instructions.length && tutorial.outro?.placement;
    updateTutorialPosition(instruction?.placement || introPosition || outroPosition || 'center');
  }, [ tutorial, instructions, index, tutorialHeight, tutorialName, updateTutorialPosition ]);

  useEffect(() => {
    const instruction = instructions[index];
    if (instruction) {
      // Highlight the new element needed to click to advance
      setTimeout(() => {
        const { mainTarget, otherTargets } = instruction;
        const target = document.querySelector(`[data-obid="${mainTarget || ''}"]`);
        if (target) {
          ripple({ target, iterationCount: 10 });
        }
        else if (otherTargets) {
          const target = document.querySelector(`[data-obid="${otherTargets[0] || ''}"]`);
          target && ripple({ target, iterationCount: 10 });
        }
      }, 1000);
      // Focus the target element if needed
      if (instruction.autoFocus) {
        const { mainTarget } = instruction;
        const target: HTMLElement | null = document.querySelector(`[data-obid="${mainTarget}"]`);
        if (target) {
          // Focus the element next render or we incur a race. See: CLIENT-3713
          setTimeout(() => {
            target.focus();
          }, 0);
        }
      }
      // Run stuff if provided
      if (instruction.doBefore) {
        instruction.doBefore();
      }
    }
    if (index <= instructions.length) {
      tracking.logEvent('Tutorial Step Started', {
        step_number: index + 1,
        step_name: getStepName(index),
        tutorial_name: tutorialName,
      });
    }
  }, [ instructions, index, ripple, getStepName, tutorialName ]);

  useEffect(() => {
    if (rageClickCount === 2) {
      tracking.logEvent('Tutorial Happy Path Left', {
        step_number: index + 1,
        step_name: getStepName(index),
        tutorial_name: tutorialName,
      });
    }
  }, [ rageClickCount, index, tutorialName, getStepName ]);

  const intro = index === -1 ? getIntro(router.query['initiated-from']) : null;
  const instruction = instructions[index];
  const container = document.body;
  const { x, y } = tutorialPosition;

  if (index <= instructions.length) {
    return ReactDOM.createPortal(
      <>
        <div className={csx(styles.backdrop, index === -1 && styles.blurIntro, index === instructions?.length && styles.blurOutro)} />
        <div id="doc-tutorial" ref={ref} className={styles.tutorial} style={{ transform: `translate(calc(${x}), calc(${y}))` }}>
          <div className={styles.inner} ref={tutorialRef as LegacyRef<HTMLDivElement>}>
            <div className={styles.closeButton} data-obid="ob-exit">
              <IconButton ariaLabel="close" buttonSize="small" buttonType="grayScale" iconName="window-close" onClick={onExitIntent} />
            </div>
            {instruction &&
              <>
                <div className={styles.appear}>
                  <h2 className={styles.title}>{instruction.title}</h2>
                  <p className={styles.body}>{instruction.body}</p>
                  {instruction.clipboardCells && (
                    <div className={styles.buttons} data-obid="ob-button">
                      <Button
                        onClick={() => {
                          writeClipboardCells(doc.model, instruction.clipboardCells?.cells, instruction.clipboardCells?.colWidths);
                          advance();
                        }}
                        data-obid="paste-data-button"
                        >Give me data
                      </Button>
                    </div>
                  )}
                  {instruction.noActionRequired &&
                    <div className={styles.buttons}>
                      <Button buttonType="secondary" data-obid="ob-continue">Continue</Button>
                    </div>
                  }
                  {instruction.onDoItForMe &&
                    <div className={styles.doItForMeButton}>
                      <Button
                        buttonType="tertiary"
                        onClick={instruction.onDoItForMe}
                        data-obid="ob-button"
                        >Do it for me 😌
                      </Button>
                    </div>
                  }
                </div>
                {instruction.gif && <img src={instruction.gif} className={styles.gif} />}
                {rageClickCount > 1 &&
                  <div className={styles.rageClickText}>
                    Only specific actions are enabled in the tutorial. <strong className={styles.endTutorial} onClick={onExitIntent} data-obid="ob-exit">Ready to leave tutorial?</strong>
                  </div>}
                <div className={styles.progress}>
                  <div className={styles.bar}><div style={{ width: `${(index + 1) / instructions.length * 100}%` }} /></div>
                </div>
              </>}
            {intro &&
              <>
                <h2 className={styles.title}>{intro.title}</h2>
                <p className={styles.body}>{intro.body}</p>
                <div className={csx(styles.buttons, styles.stacked)} data-obid="ob-button">
                  <div className={styles.fullWidth}>
                    <Button buttonType="primary" buttonSize="medium-large" onClick={() => advance()}>Get started</Button>
                  </div>
                  {intro.showAbortOption &&
                    <div className={styles.secondary}>
                      <Button buttonType="tertiary" onClick={() => setPostTutorialOption('abort')}>Maybe later</Button>
                    </div>}
                </div>
              </>}
            {index === instructions.length && mode === 'build' &&
              <>
                <h2 className={styles.title}>{initiatedFrom === 'tutorial-gallery' ? 'You\'re done!' : tutorial.outro.title}</h2>
                <p className={styles.body}>{initiatedFrom === 'tutorial-gallery' ? 'Keep going and learn more about how you can use Calculator Studio 💪' : tutorial.outro.body}</p>
                {tutorial.outro.gif && <img src={tutorial.outro.gif} className={styles.gif} />}
                <div className={csx(styles.buttons, styles.stacked)} data-obid="ob-button">
                  <Button buttonType="primary" buttonSize="large" onClick={onExitIntent}>Continue</Button>
                </div>
              </>}
            {index === instructions.length && mode === 'preview' &&
              <>
                <h2 className={styles.title}>Want to learn how to do this?</h2>
                <p className={styles.body}>The interactive tutorial is fun and takes less than 2 minutes to complete.</p>
                <div className={csx(styles.buttons, styles.stacked)} data-obid="ob-button">
                  <div className={styles.fullWidth}>
                    <Button buttonType="primary" buttonSize="medium-large" onClick={startTutorial}>Get started</Button>
                  </div>
                  <div className={styles.secondary}>
                    <Button buttonType="tertiary" onClick={() => setPostTutorialOption('abort')}>Maybe later</Button>
                  </div>
                </div>
              </>}
          </div>
        </div>
        <div
          className={styles.ripple}
          ref={rippleRef}
          style={{ width: `${rippleProps.size}px`, height: `${rippleProps.size}px`, top: `${rippleProps.y}px`, left: `${rippleProps.x}px` }}
          />
        {index > -1 &&
          <div className={styles.leaveTutorialBanner}>
            <div className={styles.gut}>
              <p>Ready to explore on your own?</p>
              <Button
                buttonType="tertiary"
                onClick={() => {
                  tracking.logEvent('Leave Tutorial Banner Clicked', {
                    tutorial_name: tutorialName,
                    step_number: index + 1,
                    step_name: instruction?.name,
                    tutorial_initiated_from: initiatedFrom,
                  });
                  onExitIntent();
                }}
                data-obid="ob-exit"
                >Leave tutorial
              </Button>
            </div>
          </div>}
        {showConfetti &&
          <Confetti
            width={window.innerWidth || document.body.clientWidth}
            height={window.innerHeight || document.body.clientHeight}
            confettiSource={{ x: getViewport().width / 2 - 200, y: getViewport().height, w: 400, h: 0 }}
            numberOfPieces={200}
            initialVelocityX={-20}
            initialVelocityY={60}
            wind={0.1}
            gravity={0.2}
            recycle={false}
            style={{ zIndex: 1000 }}
            onConfettiComplete={() => setShowConfetti(false)}
            />
          }
        {postTutorialOption &&
          <PostTutorialOptions
            variant={postTutorialOption}
            onOptionSelected={trackExit}
            onAbort={() => setPostTutorialOption(undefined)}
            />}
        {showPreviewMessage &&
          <TutorialPreviewMessage onHidePreviewMessage={() => setShowPreviewMessage(false)} />}
      </>,
      container);
  }
  return null;
};
